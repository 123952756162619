import jquery from 'jquery'

window.$ = window.jQuery = jquery
require('jquery.easing')
import SimpleLightbox from 'simplelightbox/dist/simple-lightbox.esm'

$(function () {
  'use strict'

  $('a.page-scroll[href*="#"]:not([href="#"])').on('click', function () {
    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      && location.hostname === this.hostname) {
      let target = $(this.hash)
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')

      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top - 60)
        }, 1200, 'easeInOutExpo')
        return false
      }
    }
  })

  $(window).on('scroll', () => {
    const scroll = $(window).scrollTop()
    const navbarArea = $('.navbar-area')
    const scrollLink = $('.page-scroll')

    if (scroll < 20) {
      navbarArea.removeClass('sticky')
    } else {
      navbarArea.addClass('sticky')
    }

    if ($(this).scrollTop() > 600) {
      $('.back-to-top').fadeIn(200)
    } else {
      $('.back-to-top').fadeOut(200)
    }

    const scrollbarLocation = $(this).scrollTop()

    scrollLink.each((i, el) => {
      const sectionOffset = $(el.hash).offset().top - 72

      if (sectionOffset <= scrollbarLocation) {
        $(el).parent().addClass('active')
        $(el).parent().siblings().removeClass('active')
      }
    })
  })

  $('.back-to-top').on('click', event => {
    event.preventDefault()

    $('html, body').animate({
      scrollTop: 0
    }, 1000)
  })

  $('.swiper-slide img').each(function () {
    const src = $(this).attr('src')
    $(this).attr('data-src', src)
    $(this).removeAttr('src')
  })

  new Swiper('.swiper', {
    loop: true,
    lazy: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })

  new SimpleLightbox('.gallery a', {
    overlayOpacity: .9
  })
})
